import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { styles } from '../styles/globals'
import DefaultLayout from '../containers/default'
import Wrap from '../components/layout/wrap'
// import EmailCapture from '../components/email-capture'
import '../styles/index.css'

import JawboneHealthLogo from '../svgs/jawbone-health-logo.svg'

const Header = styled.header`
    background-color: ${styles.bannerBackground};
    color: #fff;
    height: 60%;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Body = styled(Wrap)`
    padding: 5rem 0;
    transition: padding 0.5s ease-out;
`

const StyledJawboneHealthLogo = styled(JawboneHealthLogo)`
    width: 250px;
    fill: ${styles.colors.white};
    margin-top: 30px;
    margin-bottom: 40px;

    @media screen and (min-height: 900px) {
        margin-bottom: 80px;
    }
`

const Title = styled.p`
    font-size: 2rem;
    margin-bottom: 50px;
`

const Subtitle = styled.p`
    font-size: 1.5rem;
`

const EmailLink = styled.a`
    color: #000;
    text-decoration: underline;
`

export default () => (
    <DefaultLayout>
        <Helmet>
            <title>Jawbone Health</title>
            <meta
                name="description"
                content="We detect lifestyle diseases early and help members manage existing conditons to influence health outcomes. Sign up to learn more."
            />
        </Helmet>
        <Header>
            <StyledJawboneHealthLogo />
            <Title>
                Prevention when possible is always better than a cure.
            </Title>
            <Subtitle>
                We catch lifestyle diseases early &amp; guide you toward a
                healthier life.
            </Subtitle>
        </Header>
        <Body skinny as="section">
            <h1>Keep up to date</h1>
            <p>
                For any service related questions please contact us at{' '}
                <EmailLink href="mailto:cs@jawbonehealth.com">
                    cs@jawbonehealth.com
                </EmailLink>
            </p>
        </Body>
    </DefaultLayout>
)
